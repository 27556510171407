import { inject, Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs';
import { InquiryService } from 'src/app/shared/services/inquiry.service';
import { wasteTypetoString } from 'src/app/shared/utils/adapters';

@Pipe({
  name: 'wasteTitle',
  standalone: true,
})
export class WasteTitle implements PipeTransform {
  inquiryService = inject(InquiryService);

  transform(aut: string) {
    return this.inquiryService
      .getWasteTypeByGtin(aut)
      .pipe(map((waste) => wasteTypetoString(waste)));
  }
}
